import React from "react";
import Cookies from "js-cookie";
import AppConfig from "../../config/app.config";
import Backdrop from "@material-ui/core/Backdrop";

export class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 1,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ isLoading: 1 });
    if (Cookies.get("userToken") != null && Cookies.get("userToken") !== "") {
      const queryParams = new URLSearchParams(window.location.search);
      let redirectUrl = "";
      if (queryParams.get("reqUrl") != null) {
        redirectUrl = window.atob(queryParams.get("reqUrl"));
      } else if (
        Cookies.get("reqUrl") != null &&
        Cookies.get("reqUrl") !== ""
      ) {
        redirectUrl = window.atob(Cookies.get("reqUrl"));
      } else {
        redirectUrl = AppConfig.API.REDIRECT_URL;
      }
      window.sessionStorage.clear();

      const url = new URL(redirectUrl);
      const domain = url.hostname;
      const isSubDomain = domain.includes("veuit");
      if (domain != null && domain !== "" && !isSubDomain) {
        const userToken =
          Cookies.get("userToken") != null && Cookies.get("userToken") !== ""
            ? window.btoa(Cookies.get("userToken"))
            : "null";
        const thingId =
          Cookies.get("thingId") != null && Cookies.get("thingId") !== ""
            ? window.btoa(Cookies.get("thingId"))
            : "null";
        const appSessionLogId =
          Cookies.get("appSessionLogId") != null &&
          Cookies.get("appSessionLogId") !== ""
            ? window.btoa(Cookies.get("appSessionLogId"))
            : "null";
        const authorizedLoginId =
          Cookies.get("authorizedLoginId") != null &&
          Cookies.get("authorizedLoginId") !== ""
            ? window.btoa(Cookies.get("authorizedLoginId"))
            : "null";

            //userToken=${userToken}&
        const queryParametres = `userToken=${userToken}&thingId=${thingId}&appSessionLogId=${appSessionLogId}&authorizedLoginId=${authorizedLoginId}`;

        window.location.href = `${AppConfig.API.BASE_PATH != null ? redirectUrl : redirectUrl + '?' + queryParametres}`
      } else {
        window.location.href = redirectUrl;
      }
    } else {
      this.props.history.push("/sign-in");
    }
  }

  render() {
    return this.state.isLoading === 1 ? (
      <Backdrop style={{ zIndex: 100, color: "#003699" }} open>
        <img
          src={require("../../assets/images/Veuit-Spinner.gif")}
          style={{
            textAlign: "center",
            display: "block",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
          }}
          className="c-logo"
          alt="streamit"
        />
      </Backdrop>
    ) : (
      <>Home</>
    );
  }
}

export default Home;
