import { combineReducers } from "redux"
import Mode from "./mode/rtlmode"
import signUpReducer from "./signUp/signUp.reducer"
import signInReducer from "./signIn/signIn.reducer"

export const rootReducer = combineReducers({
  mode: Mode,
  signUp: signUpReducer,
  signIn: signInReducer,
})
