const URL = "https://www.myciright.com/Ciright";

const AppConfig = {
  API: {
    ENDPOINT: URL + "/api",
    BASE_PATH: null,
    DOMAIN_URL: ".veuit.com",
    REDIRECT_URL: "https://veuit.com",
    SUBSCRIPTION_ID: 8263180,
    VERTICAL_ID: 2453,
    NETWORK_APP_ID: 2459,
    APP_ID: 2809,
    SPHERE_TYPE_URL: "veuit-login-main.htm",
    SPHERE_TYPE_ID: 2,
  },
};

export default AppConfig;
