import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import Backdrop from "@material-ui/core/Backdrop";
import { ReactComponent as VeuitLogo } from "../../assets/images/veuit-logo.svg";
import Cookies from "js-cookie";
import "../../assets/css/style.css";
import UserService from "../../services/user/user.service";
import { selectLoginStatus } from "../../store/signIn/signIn.selectors";
import AppConfig from "../../config/app.config";

const mapStateToProps = (state) => {
  return {
    selectLoginStatus: selectLoginStatus(state),
  };
};

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

export class Verification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      rememberModal: false,
    };
    this.refArr = [...Array(4)].map((i) => React.createRef());
  }

  async componentDidMount() {
    const { refArr } = this;
    if (refArr[0] != null) {
      refArr[0].current.focus();
    }
    const { selectLoginStatus, history } = this.props;
    if (window.sessionStorage.getItem("authId") == null) {
      history.push("/sign-in");
    } else if (window.sessionStorage.getItem("sent") == null) {
      const res = await UserService.sendOtp({
        twoFactorAuthenticationLogId: parseInt(
          window.sessionStorage.getItem("authId")
        ),
      });
      if (!res.status && res.error != null) {
        NotificationManager.error("Couldn't Send Otp.", "", 2000);
      } else {
        window.sessionStorage.setItem("sent", res.data.verificationId);
      }
    }
  }

  getOtp() {
    return this.refArr.reduce((prev, curr) => {
      return prev + curr.current.value;
    }, "");
  }
  handleClick = async () => {
    this.setState({ isLoading: true });
    try {
      const res = await UserService.verifyOtp({
        verificationId: parseInt(window.sessionStorage.getItem("sent")),
        verticalId: AppConfig.API.VERTICAL_ID,
        subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
        appId: AppConfig.API.APP_ID,
        otp: parseInt(this.getOtp()),
      });
      if (!res.status || res.error != null) {
        NotificationManager.error("Enter Valid Otp.", "", 2000);
        this.refArr.forEach((i) => (i.current.value = null));
        this.refArr[0].current.focus();
        this.setState({ isLoadingL: false });
      } else {
        this.setState({ isLoading: false, rememberModal: true });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handleRemember = async (remember) => {
    this.setState({ isLoading: true });
    if (remember) {
      const deviceArr = JSON.parse(Cookies.get("device") ?? "[]");
      const info = window.sessionStorage.getItem("info") ?? "";
      if (deviceArr === "") {
        if (AppConfig.API.BASE_PATH != null) {
          Cookies.set("device", JSON.stringify([JSON.parse(info.trim())]));
        } else {
          Cookies.set("device", JSON.stringify([JSON.parse(info.trim())]), {
            path: "/",
            domain: AppConfig.API.DOMAIN_URL,
            sameSite: "None",
            secure: "true",
            expires: 30,
          });
        }
      } else {
        if (AppConfig.API.BASE_PATH != null) {
          Cookies.set(
            "device",
            JSON.stringify([...deviceArr, JSON.parse(info.trim())])
          );
        } else {
          Cookies.set(
            "device",
            JSON.stringify([...deviceArr, JSON.parse(info.trim())]),
            {
              path: "/",
              domain: AppConfig.API.DOMAIN_URL,
              sameSite: "None",
              secure: "true",
              expires: 30,
            }
          );
        }
      }

      this.addRememberMe();
    }

    const cookiesPayload = {
      path: "/",
      domain: AppConfig.API.DOMAIN_URL,
      sameSite: "None",
      secure: "true",
      expires: 30,
    };

    const cookieOptions =
      AppConfig.API.BASE_PATH != null ? undefined : cookiesPayload;

    const setCookie = (key) => {
      Cookies.set(key, window.sessionStorage.getItem(key), cookieOptions);
    };

    const cookieKeys = [
      "userToken",
      "thingId",
      "appSessionLogId",
      "authorizedLoginId",
    ];

    cookieKeys.forEach(setCookie);

    // if (AppConfig.API.BASE_PATH == null) {
    //   Cookies.set("userToken", window.sessionStorage.getItem("userToken"), {
    //     path: "/",
    //     domain: AppConfig.API.DOMAIN_URL,
    //     sameSite: "None",
    //     secure: "true",
    //     expires: 30,
    //   });
    // }
    // Cookies.set("thingId", window.sessionStorage.getItem("thingId"), {
    //   path: "/",
    //   domain: AppConfig.API.DOMAIN_URL,
    //   sameSite: "None",
    //   secure: "true",
    //   expires: 30,
    // });
    // Cookies.set(
    //   "appSessionLogId",
    //   window.sessionStorage.getItem("appSessionLogId"),
    //   {
    //     path: "/",
    //     domain: AppConfig.API.DOMAIN_URL,
    //     sameSite: "None",
    //     secure: "true",
    //     expires: 30,
    //   }
    // );
    // Cookies.set(
    //   "authorizedLoginId",
    //   window.sessionStorage.getItem("authorizedLoginId"),
    //   {
    //     path: "/",
    //     domain: AppConfig.API.DOMAIN_URL,
    //     sameSite: "None",
    //     secure: "true",
    //     expires: 30,
    //   }
    // );

    await this.sendLoginNotification();
    window.sessionStorage.clear();
    if (Cookies.get("reqUrl") != null && Cookies.get("reqUrl") !== "") {
      const redirectUrl = window.atob(Cookies.get("reqUrl"));

      const url = new URL(redirectUrl);
      const domain = url.hostname;
      const isSubDomain = domain.includes("veuit");
      if (domain != null && domain !== "" && !isSubDomain) {
        const userToken =
          Cookies.get("userToken") != null && Cookies.get("userToken") !== ""
            ? window.btoa(Cookies.get("userToken"))
            : "null";
        const thingId =
          Cookies.get("thingId") != null && Cookies.get("thingId") !== ""
            ? window.btoa(Cookies.get("thingId"))
            : "null";
        const appSessionLogId =
          Cookies.get("appSessionLogId") != null &&
          Cookies.get("appSessionLogId") !== ""
            ? window.btoa(Cookies.get("appSessionLogId"))
            : "null";
        const authorizedLoginId =
          Cookies.get("authorizedLoginId") != null &&
          Cookies.get("authorizedLoginId") !== ""
            ? window.btoa(Cookies.get("authorizedLoginId"))
            : "null";

            //userToken=${userToken}&
        const queryParametres = `userToken=${userToken}&thingId=${thingId}&appSessionLogId=${appSessionLogId}&authorizedLoginId=${authorizedLoginId}`;

        this.timeoutHandle = setTimeout(() => {
          window.location.href = `${AppConfig.API.BASE_PATH != null ? redirectUrl : redirectUrl + '?' + queryParametres}`
        }, 3000);
      } else {
        this.timeoutHandle = setTimeout(() => {
          window.location.href = redirectUrl;
        }, 3000);
      }
    } else {
      this.timeoutHandle = setTimeout(() => {
        window.location.href = AppConfig.API.REDIRECT_URL;
      }, 3000);
    }
    // this.setState({ isLoading: false })
  };

  addRememberMe = async () => {
    await UserService.rememberMe({
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
      appId: AppConfig.API.APP_ID,
      email: window.sessionStorage.getItem("email"),
      timeZone: timeZone,
      twoFactorAuthenticationLogId: parseInt(
        window.sessionStorage.getItem("authId")
      ),
      thingId: parseInt(window.sessionStorage.getItem("thingId")),
    });
  };

  sendLoginNotification = async () => {
    await UserService.sendLoginNotification({
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
      appId: AppConfig.API.APP_ID,
      email: window.sessionStorage.getItem("email"),
      timeZone: timeZone,
      appSessionLogId: parseInt(
        window.sessionStorage.getItem("appSessionLogId")
      ),
    });
  };

  render() {
    return (
      <div>
        {" "}
        <section className="sign-in-page" style={{ position: "relative" }}>
          {this.state.isLoading && (
            <Backdrop style={{ zIndex: 10000, color: "#003699" }} open>
              <img
                src={require("../../assets/images/Veuit-Spinner.gif")}
                style={{
                  textAlign: "center",
                  display: "block",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                }}
                className="c-logo"
                alt="streamit"
              />
            </Backdrop>
          )}
          <Container id="container-signin">
            {this.state.rememberModal && (
              <section
                style={{
                  backgroundColor: "rgba(0,0,0,0.6)",
                  position: "absolute",
                  inset: 0,
                  zIndex: 5000,
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <div className="remember-modal">
                  <h4>Remember This Device?</h4>
                  <p>
                    By remembering this device, you will not be asked for one
                    time password next time you login on this device.
                  </p>
                  <div className="set-verify-btns">
                    <button
                      className="verify-remember-btn"
                      onClick={() => {
                        this.handleRemember(true);
                      }}
                    >
                      Remember
                    </button>
                    <button
                      className="verify-cancel-btn"
                      onClick={() => {
                        this.handleRemember(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </section>
            )}
            <Row className="justify-content-center align-items-center height-self-center m-0">
              <div id="set-sign-in1">
                <Col
                  className="align-self-center set-sign-height"
                  id="set-sign-in"
                >
                  <div className="sign-user_card ">
                    <div className="sign-in-page-data">
                      <div className="sign-in-from w-100 m-auto">
                        <div className="sign-in-logo">
                          <Link to="/">
                            <VeuitLogo id="info-logopages" />
                          </Link>
                        </div>
                        <div className="form-bg-mobile">
                          <div className="form-bg-mobile1">
                            <Form
                              id="set-input-width"
                              onSubmit={(e) => {
                                e.preventDefault();
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  paddingBottom: "2rem",
                                  justifyContent: "space-between",
                                  maxWidth: "18rem",
                                  marginInline: "auto",
                                  gap: "20px",
                                }}
                              >
                                {this.refArr.map((i, num) => (
                                  <div key={`otp-${num}`}>
                                    <input
                                      ref={this.refArr[num]}
                                      type="number"
                                      style={{
                                        aspectRatio: "1 / 1",
                                        height: "3.5rem",
                                        textAlign: "center",
                                        borderRadius: "0.5rem",
                                      }}
                                      className="no-arrows"
                                      onChange={(e) => {
                                        if (e.target.value.length > 1) {
                                          e.target.value = e.target.value[0];
                                        } else if (
                                          e.target.value != "" &&
                                          num !== 3
                                        ) {
                                          this.refArr[num + 1].current.focus();
                                        } else if (
                                          e.target.value === "" &&
                                          num !== 0
                                        ) {
                                          this.refArr[num - 1].current.focus();
                                        }
                                        if (
                                          num === 3 &&
                                          this.getOtp().length === 4
                                        ) {
                                          this.handleClick();
                                        }
                                      }}
                                    />
                                  </div>
                                ))}
                              </div>
                              <button
                                style={{
                                  paddingBottom: "1rem",
                                  outline: "none",
                                  border: "none",
                                  background: "none",
                                  color: "white",
                                  marginInline: "auto",
                                  display: "block",
                                  fontWeight: "bold",
                                  cursor: "pointer",
                                }}
                                onClick={async () => {
                                  this.setState({ isLoading: true });
                                  try {
                                    const res = await UserService.sendOtp({
                                      twoFactorAuthenticationLogId: parseInt(
                                        window.sessionStorage.getItem("authId")
                                      ),
                                    });
                                    if (res.error == null && res.status) {
                                      window.sessionStorage.setItem(
                                        "sent",
                                        res.data.verificationId
                                      );
                                      NotificationManager.success(
                                        "Otp Resent Successfully.",
                                        "",
                                        2000
                                      );
                                    } else {
                                      NotificationManager.error(
                                        "Cant Sent Otp.",
                                        "",
                                        2000
                                      );
                                    }
                                  } catch {
                                    NotificationManager.error(
                                      "Cant Sent Otp.",
                                      "",
                                      2000
                                    );
                                  }
                                  this.setState({ isLoading: false });
                                }}
                              >
                                Resend Otp
                              </button>
                              <div className="sign-info">
                                <Button
                                  type="submit"
                                  onClick={this.handleClick}
                                  id="sign-inbtn"
                                >
                                  Verify
                                </Button>
                              </div>
                              <p
                                style={{
                                  textAlign: "center",
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                                onClick={async () => {
                                  try {
                                    await UserService.logOutSession({
                                      appId: AppConfig.API.APP_ID,
                                      email:
                                        window.sessionStorage.getItem("email"),
                                      appSessionLogId: parseInt(
                                        window.sessionStorage.getItem(
                                          "appSessionLogId"
                                        )
                                      ),
                                    });
                                  } catch {}
                                  window.sessionStorage.clear();
                                  this.props.history.push("/sign-in");
                                }}
                              >
                                Back To Login
                              </p>
                            </Form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
            </Row>
          </Container>
        </section>
      </div>
    );
  }
}

export default connect(mapStateToProps)(withRouter(Verification));
