import { Switch, Route } from "react-router-dom"
import { TransitionGroup, CSSTransition } from "react-transition-group"

import Home from "../views/home/home"
import Faq from "../views/footer-page/faq"
import PrivacyPolicy from "../views/footer-page/privacy-policy"
import TermsOfUse from "../views/footer-page/terms-of-use"
import Error from "../views/Error/Error"
import MissionPurpose from "../views/footer-page/mission-purpose"

import SignUp from "../views/signUp/signup"
import SignIn from "../views/signIn/signIn"
import ForgotPassWord from "../views/forgot-password/forgot-password"
import ResetPassword from "../views/reset-password/reset-password"
import Verification from "../views/verification/Verification"

const MainRoute = () => {
  return (
    <TransitionGroup>
      <CSSTransition
        // key={location.key}
        classNames="fade"
        timeout={300}
      >
        <Switch>
          <Route
            path="/sign-up"
            component={SignUp}
          />
          <Route
            path="/sign-in"
            component={SignIn}
          />
          <Route
            path="/forgot-password"
            component={ForgotPassWord}
          />
          <Route
            path="/reset-password"
            component={ResetPassword}
          />
          <Route
            path="/verification"
            component={Verification}
          />
          <Route
            path="/faq"
            component={Faq}
          />
          <Route
            path="/privacy-policy"
            component={PrivacyPolicy}
          />
          <Route
            path="/terms-of-use"
            component={TermsOfUse}
          />
          <Route
            path="/mission-purpose"
            component={MissionPurpose}
          />
          {/* homepage */}
          <Route
            path="/"
            exact
            component={Home}
          />
          <Route
            path="*"
            component={Error}
          />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  )
}

export default MainRoute
