import React from "react";
import MainRoute from "../router/main-route";
import Cookies from "js-cookie";
import AppConfig from "../config/app.config";
import { BrowserRouter as Router } from "react-router-dom";

export class MainLayout extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    if (
      Cookies.get("userToken") != null &&
      Cookies.get("userToken") !== "" &&
      window.sessionStorage.getItem("authId") == null &&
      window.sessionStorage.getItem("authId") === -1
    ) {
      const queryParams = new URLSearchParams(window.location.search);
      let redirectUrl = "";
      if (queryParams.get("reqUrl") != null) {
        redirectUrl = window.atob(queryParams.get("reqUrl"));
      } else if (
        Cookies.get("reqUrl") != null &&
        Cookies.get("reqUrl") !== ""
      ) {
        redirectUrl = window.atob(Cookies.get("reqUrl"));
      } else {
        redirectUrl = AppConfig.API.REDIRECT_URL;
      }
      window.sessionStorage.clear();

      const url = new URL(redirectUrl);
      const domain = url.hostname;
      const isSubDomain = domain.includes("veuit");
      if (domain != null && domain !== "" && !isSubDomain) {
        const userToken =
          Cookies.get("userToken") != null && Cookies.get("userToken") !== ""
            ? window.btoa(Cookies.get("userToken"))
            : "null";
        const thingId =
          Cookies.get("thingId") != null && Cookies.get("thingId") !== ""
            ? window.btoa(Cookies.get("thingId"))
            : "null";
        const appSessionLogId =
          Cookies.get("appSessionLogId") != null &&
          Cookies.get("appSessionLogId") !== ""
            ? window.btoa(Cookies.get("appSessionLogId"))
            : "null";
        const authorizedLoginId =
          Cookies.get("authorizedLoginId") != null &&
          Cookies.get("authorizedLoginId") !== ""
            ? window.btoa(Cookies.get("authorizedLoginId"))
            : "null";

            
        const queryParametres = `userToken=${userToken}&thingId=${thingId}&appSessionLogId=${appSessionLogId}&authorizedLoginId=${authorizedLoginId}`;

        window.location.href = `${AppConfig.API.BASE_PATH != null ? redirectUrl : redirectUrl + '?' + queryParametres}`
      } else {
        window.location.href = redirectUrl;
      }
    } else {
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams.get("reqUrl") != null) {
        if (AppConfig.API.BASE_PATH != null) {
          Cookies.set("reqUrl", queryParams.get("reqUrl"));
        } else {
          Cookies.set("reqUrl", queryParams.get("reqUrl"), {
            path: "/",
            secure: "true",
            sameSite: "None",
            domain: AppConfig.API.DOMAIN_URL,
          });
        }
      }
      // this.props.history.push("/sign-in")
    }
  }
  render() {
    return (
      <>
        <div className="content-page" id="content-page">
          <Router basename={AppConfig.API.BASE_PATH ? "/veuit-login" : "/"}>
            <MainRoute />
          </Router>
        </div>
      </>
    );
  }
}

export default MainLayout;
