import { Switch, Route } from "react-router-dom";
import MainLayout from "../layouts/main-layout";
import React from "react";
import { withRouter } from "react-router";
import AppConfig from "../config/app.config";
import Cookies from "js-cookie";

class LayoutsRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 1,
      cookiesUserToken: "",
    };
    this.getInterval = null;
  }

  componentDidMount() {
    this.getInterval = setInterval(() => this.getSessionDetail(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.getInterval);
  }

  getSessionDetail = async () => {
    const cookiesUserToken = Cookies.get("userToken");

    if (
      cookiesUserToken != null &&
      cookiesUserToken !== undefined &&
      cookiesUserToken !== "" &&
      cookiesUserToken !== this.state.cookiesUserToken &&
      window.sessionStorage.getItem("authId") == null &&
      window.sessionStorage.getItem("authId") === -1
    ) {
      this.setState({ cookiesUserToken: cookiesUserToken });
      const queryParams = new URLSearchParams(window.location.search);
      let redirectUrl = "";
      if (queryParams.get("reqUrl") != null) {
        redirectUrl = window.atob(queryParams.get("reqUrl"));
      } else if (
        Cookies.get("reqUrl") != null &&
        Cookies.get("reqUrl") !== ""
      ) {
        redirectUrl = window.atob(Cookies.get("reqUrl"));
      } else {
        redirectUrl = AppConfig.API.REDIRECT_URL;
      }
      window.sessionStorage.clear();

      const url = new URL(redirectUrl);
      const domain = url.hostname;
      const isSubDomain = domain.includes("veuit");
      if (domain != null && domain !== "" && !isSubDomain) {
        const userToken =
          Cookies.get("userToken") != null && Cookies.get("userToken") !== ""
            ? window.btoa(Cookies.get("userToken"))
            : "null";
        const thingId =
          Cookies.get("thingId") != null && Cookies.get("thingId") !== ""
            ? window.btoa(Cookies.get("thingId"))
            : "null";
        const appSessionLogId =
          Cookies.get("appSessionLogId") != null &&
          Cookies.get("appSessionLogId") !== ""
            ? window.btoa(Cookies.get("appSessionLogId"))
            : "null";
        const authorizedLoginId =
          Cookies.get("authorizedLoginId") != null &&
          Cookies.get("authorizedLoginId") !== ""
            ? window.btoa(Cookies.get("authorizedLoginId"))
            : "null";

            //userToken=${userToken}&
        const queryParametres = `userToken=${userToken}&thingId=${thingId}&appSessionLogId=${appSessionLogId}&authorizedLoginId=${authorizedLoginId}`;

        window.location.href = `${AppConfig.API.BASE_PATH != null ? redirectUrl : redirectUrl + '?' + queryParametres}`
      } else {
        window.location.href = redirectUrl;
      }
    }
  };

  render() {
    return (
      <Switch location={this.props.location}>
        <Route path="/" component={MainLayout} />
      </Switch>
    );
  }
}

export default withRouter(LayoutsRoute);
